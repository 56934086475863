<template>
  <div id="container">

    <section id="mypage">
      <div class="wrap">

        <div class="cts_box">
          <h3 class="child_ttl">クラス・支払い方法変更を受け付けました</h3>
          <p class="lead_txt alnC sp_alnL">
            クラス・支払い方法の変更が完了しました。
          </p>

          <div class="button_wrap">
            <router-link :to="{name: 'UsersCustomerTop'}" class="submit basic">複眼経済塾トップ</router-link>
          </div>
          <div class="button_wrap">
            <router-link :to="{name: 'UsersCustomerAccount'}" class="submit light skeleton">マイページ</router-link>
          </div>
        </div>

      </div>
    </section>

    <div id="b_listBox">
      <ul id="b_list">
        <li><router-link to="/">トップページ</router-link></li>
        <li><router-link :to="{name: 'UsersCustomerAccount'}">マイページ</router-link></li>
        <li><router-link :to="{name: 'UsersCustomerRank'}">塾生クラスの変更</router-link></li>
        <li>クラス変更の申請を受け付けました</li>
      </ul>
    </div>

    <div class="modal" v-bind:class="{open: modal_open}">
      <div class="modal_box">
        <div class="header">
          <h4>申し込み済みの「月例会」<br class="sp">「研修会」があります。</h4>
          <div class="close modal_close" v-on:click="modal_open = null"></div>
        </div>
        <div class="inner">
          <p>
            本科プラスの方の「月例会」「研修会」の受講料は無料です。<br />
            すでに申込み済の場合、受講料の返金手続きを行いますので <router-link to="/contacts/">事務局にご連絡</router-link>ください。
          </p>
          <div class="btn_wrap">
            <a href="javascript:void(0)" v-on:click="modal_open = null" class="submit green">確認</a>
          </div>
        </div>
      </div>
      <div class="ovr"></div>
    </div>

  </div>
</template>

<script>

import Libraries from '@/components/Libraries'

export default {
  mixins: [Libraries],
  data() {
    return {
      modal_open: false,  // モーダル非表示 ：this.$route.query.any_registered_meetings === 'true',
    }
  },
  mounted() {
    // form token
    this.removeFormToken('RankChange')
  }
}
</script>
